.site-header {
  position: absolute;
  top:0;
  left: 5%;
  height:8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

@function circumference($r){
  $pi: 3.141592653;
  @return 2*$pi*$r;
}

// ---------------------------
// Main Navigation Menu
// ---------------------------

 .nav {
 // Toggle Button
  &__toggle {
    position:absolute;
    top:0rem;
    right:0rem;
    display:inline-block;
    padding:0;
    margin: 0;
    border:0;
    background:transparent;
    outline:0;
    cursor:pointer;
    border-radius:50%;
    transition:background-color .15s linear;
    z-index:10;
    
    &:hover,
    &:focus {
     color:$red;
     background: transparent;
    }
  }

  &__menu {
    position: relative;
    top: 15vh;
    right:12vw;
    z-index:10;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    height:100vh;
    width:100%;
    padding: 0;
    visibility:hidden;
  }
  &__item {
    opacity:0;
    transition: all .3s cubic-bezier(0.000, 0.995, 0.990, 1.000) .3s;
  }
  
  @for $i from 1 through 5 {
    &__item:nth-child(#{$i}){
      transform:translateY(-40px * $i);
    }
  }
  &__link {
    display:block;
    padding:3vh 2vw;
    margin-bottom: 3vh;
    text-align:center;
    color:$white;
    font-size:$fzlink-menum;
    letter-spacing:3px;
    text-decoration:none;
    text-transform:capitalize;
    
    &:hover,
    &:focus {
      color:$red;
      outline:0;
      background-color:rgba(29, 29, 29, 0.89);
    }
   }
 
}
// ---------------------------
// SVG  Icon
// ---------------------------
.menuicon {
  display:block;
  width:5rem;
  height: 5rem;
  padding:2rem 0;
  cursor:pointer;
  color:$white;
  transform:rotate(0deg);
  transition: .3s cubic-bezier(0.165, 0.840, 0.440, 1.000); 
  &__bar,
  &__circle {
    fill:none;
    stroke: currentColor;
    stroke-width:3;
    stroke-linecap:round;
  }
  &__bar {
    transform: rotate(0deg);
    transform-origin:50% 50%;
    transition: transform .25s ease-in-out;
  }
  &__circle {
    transition: stroke-dashoffset .3s linear .1s;
    stroke-dashoffset:circumference(23); 
    stroke-dasharray:circumference(23);
  }
  .menuicon__bar{
    transform:scale(1.4);
  }
}
// ---------------------------
// Circular Splash Background
// ---------------------------
.splash {
  position:absolute;
  top:40px;
  right:40px;
  width: 1px;
  height: 1px;
  
  &::after {
    position:absolute;
    top:-142vmax;
    left:-142vmax;
    width:244vmax;
    height:244vmax;
    display:block;
    content:"";
    background-color:$ul;
    opacity: 0.98;
    // screen diameter can be 142vmax at most,
    // circle needs to be twice that size to cover it
    transform: scale(0);
    transform-origin:50% 50%;
    transition: transform .5s cubic-bezier(0.755, 0.050, 0.855, 0.060);
    // will-change tells the browser we plan to 
    // animate this property in the near future
    will-change:transform;
    z-index: 3;
  }
}

// ---------------------------
// Active State
// ---------------------------
.nav:target,
.nav--open {

  //scale the background circle to full size
  > .splash::after {
    transform:scale(1);
  }
  //animate the menu icon
  .menuicon {
    color:$white;
    transform:rotate(180deg);
    
    &__circle {
      stroke-dashoffset:0;
    }
    &__bar:nth-child(1),
    // 
    &__bar:nth-child(4) {
      opacity:0;
    }
    &__bar:nth-child(2) {
      transform: rotate(45deg);
    }
    &__bar:nth-child(3) {
      transform: rotate(-45deg);
    }
    &:hover{
      color:$red;
    }
  }

  //show the nav items
  .nav {
    &__menu {
      visibility:visible;
      padding-top:150%;
    }
  
    &__item {
      opacity:1;
      transform:translateY(0);
    }
  }
 }


//tablet
@media screen and (min-width:768px){
  .nav__menu {
    position: relative;
    top: 16vh;
    right:35%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    height:100vh;
    width:100%;
    padding: 0;
    //visibility:hidden;
    z-index:10;
  }

  .nav__link{
    font-size: $fzlink-menut;
    padding:3rem 5rem;
  }
  .menuicon {
    width:7rem;
    height: 7rem;
    padding: 4.5rem 2rem;
  }
  
}

// desktop
@media screen and (min-width:1200px){
  .viewport {
    height:12rem;
  }
  .site-header {
    top:2rem;
    height:12rem;
    background-color:transparent;
  }
  .nav__toggle{
    display:none;
  }
  .nav {
    width:100%;
    
  
    //Toggle Button
  &__toggle {
      position: absolute;
      right:1.5vw;
      top:1rem;
      padding:0;
      margin: 0;
      border:0;
      background:transparent;
      outline:0;
      cursor:pointer;
      border-radius:50%;
      transition:background-color .15s linear;
      z-index:5;
      
      &:hover,
      &:focus {
       color:$red;
      }
    }
    &__menu {
      position:relative;
      top: 0rem;
      left:0;
      display:flex;
      flex-direction:row;
      justify-content:flex-end;
      align-items:center;
      height:20vh;
      width:87vw;
      visibility:visible;
      font-family: $ffp , sans-serif;
      background-color: transparent;
    }
    &__item:last-child{
      margin-right: 4rem;
    }
    &__item{
      font-size: $fzNavbard;
      opacity:1;
      transition: all .3s cubic-bezier(0.000, 0.995, 0.990, 1.000) .3s;
      margin:2rem 4rem 0;
      padding:0vw;

      &:hover,
      &:focus{
        color:$red;
        outline:0;
        background-color:transparent;
      }
      
    }
    @for $i from 1 through 5 {
      &__item:nth-child(#{$i}){
        transform:translateY(0px * $i);
      }
    }
    &__link {
      display:block;
      padding: 0;
      margin: 0;
      text-align:center;
      text-transform:capitalize;
      color:$white;
      letter-spacing:0px;
      font-size:$fzNavbard;
      text-decoration:none;
    }
  }
  .nav__item::after  {
    display: block;
    position: relative;;
    top: 2px;
    content:" ";
    border-bottom: 1.5px solid $red;
    width:0%;
  }
  .nav__item:hover::after, .nav__item:focus::after{
    display: block;
    position: relative;;
    top: 2px;
    content:" ";
    border-bottom: 1.5px solid $red;
    width:100%;
    transition: width ease-in 0.5s;
  }
  .current-active{
    color:$red;
  }
  .splash::after{
    display: none;
  }
.nav__link:hover, .nav__link:focus, .nav__item:hover{
  background-color: transparent !important;
}
}/*fermeture media*/


