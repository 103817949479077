
.up{
  position: fixed;
  display: none;
  cursor:pointer;
  bottom:4vh;
  left:84vw;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  color:$white;
  background-image: linear-gradient(to right, #fff , #f8008c 75%);
  text-align: center;  
  z-index:5;
  & i{
    font-size: 4rem;
  }

}

@media screen and (orientation: landscape)and(max-width:767px){
  .main__button a{
      width: 65vw;
      height: 6vh;
      padding: 1rem 0.5vw;
      line-height:6vh;
      color:$red;
      font-size: 2.2rem;
      font-family: $ffmc;
      font-weight: bold;
      letter-spacing: 3px;
      text-transform: uppercase;
      text-decoration: none;
      border:3px solid $red;
  
      &:hover{
        transition: 0.5s ease-in;
        background-color: $white;
      }
    }
}
//tablet
@media screen and (min-width:768px){
  .main__button{
    top:50vh;
    & a{
      line-height: 5vw;
      height: 5vw;
      padding:1vh 0.5vh;
      border:5px solid $red;
      font-size: 3.8rem;
    }
  }
  .up{
    display: flex;
    align-items: center;
    justify-content: center;
    left:87vw;
    width: 5rem;
    height: 5rem;
    line-height: 5;
    
    & i{
      font-size: 4.5rem;
    }
  }

}
//desktop
@media screen and (min-width:1200px){
  .main__button{
    top:3vh;
    & a{
      width:19vw;
      height: 2.4vh;
      line-height: 2.4vh;
      font-size: $fzbuttond;
      font-weight: normal;
      border:3px solid $red;
      &:hover{
       opacity: 0.9;
      }
    }
  }
  .up{
    left:96vw;
    width:4rem;
    height: 4rem;
    line-height: 4rem;
    
    & > i{
      font-size: 3.3rem;
    }
  }
}