.body--admin{
  background: red;
  width:100vw;
  height:100vh;
}
.main--admin{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// .container--admin{
 
// }


