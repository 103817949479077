//base repise sur aboutus, class comunes


.portfolio{
  position: absolute;
  top:10vh;
  left:50%;
  transform: translatex(-50%);
  width:100%;
  background-color: #f1f1f1;
  overflow: hidden;
  z-index: 2;
}

.title--portfolio{
  margin:0;
}
.title--h3{
  font-size: 2.5rem;
  margin: 6vh 3vh 0vh;
  color:$red;
  font-family: $ffp;
  align-items: flex-start;
}

.grid-container {
  columns: 1;
  column-gap: 3rem;
  width: 95%;
  margin: 0vh auto 5vh;
  div {
    margin: 2vh auto 0;
    display: inline-block;
    padding: 5px;
    width:95%;
    border-radius: 5px;
    transition: all .25s ease-in-out;
    overflow: hidden;
    img {
      width: 100%;
      border-radius: 5px;
      transition: all .25s ease-in-out;
      margin:0 auto;
    }
    p {
      margin: 2px 0;
      padding: 0;
      text-align: center;
      font-style: italic;
    }
  }
}
.grid-item-7{
  transform:scale(1.8);
}
iframe {
  width: 100%;
  border-radius: 5px;
}
.grid-container--parlent{
 
  column-gap: 3rem;
  columns: 1;
  padding:  0.5vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  columns: 1;
  column-gap: 2rem;
  width: 100%;
  margin: 1rem auto;
  div {
    margin: 1.5vh  auto 1.5vh;
    display: inline-block;
    width: 100%;
    height: auto;
    border: none;
    overflow: hidden;
  }
  img {
    width: 100%;
    border-radius: 5px;
    transition: all .25s ease-in-out;
    margin:0 auto;
  }
}
.emissions{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: flex-start;
  margin: 2rem auto;
  width:90%;
  height:auto;
  padding: 2rem;
  text-align: center;

    img{
      width:100%;
      height:auto;
    }
  div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.parlent{
   display: flex;
   flex-wrap: wrap;
  //  justify-content: center;
  align-items: center;
   margin: 2rem auto;
   width:90%;
   height:auto;
   // padding: 2rem;
   
   div{
    width:100%;
    height:auto;
    margin-bottom:1rem;
   }
   img{
     width:100%;
     height:auto;
     text-align: center;
     border-radius: 0.5rem;
    //  margin:2rem;
    }
}
.stars{
  display: flex;
  justify-content: center;
}
// tablet
@media screen and (min-width:768px){
  .portfolio{
    top:18vh;
  }
  .grid-container {
    columns: 2;
  }
  .title--h3{
    font-size: 3.5rem;
    margin: 0vh 3vh 0vh;
    color:$red;
  }
}
// desktop
@media screen and (min-width:1200px){
  .portfolio{
    position: absolute;
    top:15vh;
  }
  .title--portfolio{
    font-size: 6rem;
    color:$ul;
  }
  .title--h3{
    font-size: 3rem;
    align-self: flex-start;
    margin: 0vw vh 3vw;
    color:$red;
  }
    .grid-container {
      columns: 6;
      padding: 0.5vh;
      div:hover{
        will-change: transform;
        transform:scale(1.4);
        transition: all .25s ease-in-out;
      }
  }
  .grid-container--videos{
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap;
    columns: 1;
    column-gap: 2rem;
    width: 95%;
    margin: 1rem auto;
    div {
      
      margin: 0 1.5rem 1.5rem 0;
      display: inline-block;
      width: 100%;
      border: solid 2px black;
      padding: 5px;
      box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
      border-radius: 5px;
      transition: all .25s ease-in-out;
      overflow: hidden;
    }
 
    div:hover{
      transform: scale(1);
    }
  }
  .grid-container--parlent{
    columns: 6;
    padding: 0.5vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    columns: 1;
    column-gap: 2rem;
    width: 100%;
    margin: 1rem auto;
    div {
      margin: 1.5vh  auto 1.5vh;
      display: inline-block;
      width: 22%;
      height: auto;
      border: none;
     
      transition: all .25s ease-in-out;
      overflow: hidden;
    }
    img {
    
      width: 100%;
      border-radius: 5px;
      transition: all .25s ease-in-out;
      margin:0 auto;
    }
  }
  .card--content{
    font-size: 1.5rem;
    margin-bottom:0.2rem;
    padding:0;
  }
  
  .emissions{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: flex-start;
    margin: 2rem auto;
    width:80%;
    height:auto;
    padding: 2rem;

      img{
        width:50%;
        height:auto;
        text-align: center;
      }
    div{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

 .parlent{
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     margin: 2rem auto;
     width:100%;
     height:auto;
     // padding: 2rem;
     
     div{
      width:22%;
      height:auto;
      margin: 2rem;
     }
     img{
       width:100%;
       height:auto;
       text-align: center;
       border-radius: 0.5rem;
       margin:2rem;
      }
  }

}