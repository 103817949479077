.social{
  position: absolute;
  display: block;
  top:46%;
  left:90vw;
  transform: translate( -50%);
}
  
  .social__item{
    margin: 0.5rem 0.5rem;
    cursor: pointer;
    padding: 0.8rem;
    border-radius: 0.8rem;
    color:$white;
    font-size:2.4rem;
    transition: ease-in-out 1s;
    
  }
  .social__item:hover{
    color:$red;
    border-color:$red;
  
  }
  .facebook{
    padding: 0.4rem 1.3rem;
    
  }
  .youtube{
    padding: 0.8rem 0.6rem;
  }
 

//version tablet===================================
@media screen and (min-width:768px){
  .social{
    display: block;
    top:46%;
    left:91vw;
    transform: translate( -50%);
  }
    .social__item{
      margin: 0.5rem 1rem;
      cursor: pointer;
      padding: 0.8rem;
      border-radius: 0.8rem;
      color:$white;
      font-size:2.4rem;
      transition: ease-in-out 1s;
    }
    .social__item:hover{
      color:$red;
      border-color:$red;
    
    }
    .facebook{
      padding: 0.4rem 1.3rem;
      
    }
    .youtube{
      padding: 0.8rem 0.6rem;
    } 
    .social__item{
      font-size: 3.8rem;
      margin: 0.5rem 0.5rem;
    }
  .facebook{
    padding: 0.8rem 1.5rem;
  }
  .youtube{
    padding: 0.8rem 0.6rem;
  }
}


//version desktop
@media screen and (min-width:1200px){
  .social{
    display: block;
    top:46%;
    left:97vw;
    transform: translate( -50%);
  }
  .social__item{
    margin: 1rem 1rem;
    cursor: pointer;
  
    color:$white;
    font-size:2.8rem;
    transition: ease-in-out 1s;
  }
  .social__item:hover{
    color:$red;
    border-color:$red;
  }
  .facebook{
    padding: 0.8rem 1.5rem;
  }
}

