.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: $black;
  height: 15vh;
  width: 100%;
  border-top: 1px solid rgb(61, 61, 61);
  overflow: hidden;
}

.footer> ul{
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
padding-top: 3rem;
& li{
  margin:0.5vh 6rem;
  
}
& li:hover{
  animation: scale 2s ease;
}
}
@keyframes scale{
  from{
      transform: scale(1);
  }
  50%{
    transform: scale(1.1);
  }
  to{
    transform: scale(1);
  }
}
.footer__content{
  color:$red;
  font-size: 1.4rem;
}
.footer__content--p{
  font-size: 1.2rem;
  color:transparent !important;
  -webkit-background-clip:text;
  background-image: linear-gradient(to right, #fff 35%, #f8008c 75%);
  padding: 1rem;
}

//tablet
@media screen and (min-width:768px){
  
  .footer__list{
  display: flex;
  flex-direction: row!important;
  justify-content: space-around;
  align-items: center;
  & li{
      margin:0.5vh 10rem;
      }
  }
  
  .footer__content{
    font-size: 2vh;
  }
  .footer__content--p{
    font-size: 1.4rem;
  }
}
//desktop
@media screen and (min-width:1200px){
  .footer{
    height: 30vh;
  }
  .footer__list{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .footer__content{
    font-size: 2rem;
    padding: 0 12vw;
  }
  .footer__content--p{
    font-size: 1.5rem;
  }
}