.logo{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  width:12vw;
  height:12rem;
}
.main__logo{
  position: relative;
  top:1rem;
  left:0;
  width: $fzlm;
  height: auto;
}

//tablet
@media screen and (min-width:768px){
  .main__logo{
  top:4rem;
  width: $fzlt;
  left:3rem;
  }
}

//desktop
@media screen and (min-width:1200px){
  .main__logo{
  top:0rem;
  left:2rem;
  width: $fzl;
  }
}