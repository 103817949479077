.container-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
// .bg-vagues{
  
//   width: 100%;
// }
.vague {
  position: absolute;
  bottom: -10vw;
  left:0;
  z-index: -1;
  width: 102%;
  margin:0;
  padding:0;
}
.bg-orchestre {
  filter: blur(1.5px);
}
picture {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100vh;
  }
}
.site {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.picture>.johann {
  position: absolute;
  bottom: 0vh;
  left: -11rem;
  height: 62vh;
}
.opacity {
  filter: blur(1.5px);
}

.notescool {
  display: none;
}


@media screen and (orientation: landscape) and(max-width:1050px) {
  .picture>.johann {
    position: absolute;
    bottom: 0vh;
    left: 0rem;
    height: 66vh;
  }
}


//tablet
@media screen and(min-width:768px) {
  .background {
    width: 100%;
    height: auto;
  }

  // .vague {
  //   position: relative;
  //   top: -4vh;
  //   z-index: -1;
  // }
}


//desktop
@media screen and (min-width:1200px) {
  .background {
    width: 100%;
    height: auto;
  }

  .picture>.johann {
    position: absolute;
    bottom: 0;
    left: -10rem;
    height: 95vh;
  }

  .notescool {
    display: block;
    position: absolute;
    top: 7vh;
    left: 69vw;
    height: 100vh;
    width: 50vh;
    transform: rotate(20deg);
    animation: apparition3 5s ease-in;
  }

  @keyframes apparition3 {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}