.mentions{
  position: absolute;
  top:14vh;
  left:50%;
  transform: translatex(-50%);
  width:100%;
  background-color: #f1f1f1;
  overflow: hidden;
  z-index: 2;
  }
  .container--mentions{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:2rem auto;
    width:100%;
    background-color: $white;
  }

.descriptif-rgpd{
  font-size: 1.8rem;
  margin:1rem auto;
  align-self: flex-start;
  border-bottom: 1px solid $red;
  color:#474544;
}  
.card-mentions{
  display: flex;
  flex-direction: column;
}
.h3-mentions{
  font-size: 1.5rem;
  align-self: flex-start;
  margin: 2rem  2rem 0rem;
}
.p-descriptif--rgpd{
  font-size:1.4rem;
  color: #474544;
  line-height: 1.4;
  text-align: justify;
  padding: 0 2rem ;
}

@media screen and (min-width:768px){
  .mentions{
    top:14vh;
  }
  .descriptif-rgpd{
   align-self:flex-start;
   margin:0;
  } 

  .p-descriptif--rgpd{
    font-size: 1.8rem;
  }
}
@media screen and (min-width:1200px){
  .mentions{
    width:60%;
  }
  .descriptif-rgpd{
   align-self:flex-start;
   margin:0;
   font-size: 2.5rem;
  } 
  .h3-mentions{
    font-size: 2rem;
  }
 
 .p-descriptif--rgpd{
    font-size: 1.7rem;
  }
}