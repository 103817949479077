//général

$black: #000;
$white:#fff;
$red:#f8008c;
$bg-color:linear-gradient(rgb(0, 0, 0),#000);
$ul:#0e0d0d;
$bgcard: #f1f1f1;
$bg-navbard:$black;
$background:white;

//font family
$ffp:  'Dancing Script', sans-serif;
//font title
$ffh1:'Dancing Script', sans-serif;
//font du contenu principal
$ffmc: sans-serif;
//menu dropdown
$ffdropdown--menu: helvetica, sans-serif;
//font family monntserrat formulaire
$ffmtt:'Montserrat', Arial, sans-serif;

//font-size
//logo
$fzlm:5.5rem;//logo mobile
$fzlt:8rem;//logo tablette
$fzl:7rem;//logo desktop

// h1 and content
$fzh1m:3.6rem;//la cie play mobile
$fzh1t:6.4rem;//la cie play tablet
$fzh1d:5rem;//la cie play desktop
$fzmcm:2.2rem;//content mobile
$fzmct:3.3rem;//content tablet
$fzmcd:2.7rem;//content desktop

//button and menu mobile
$fzlink-menum:2rem; //menu mobile
$fzlink-menut:3rem;//menu tablette
$fzbuttonm:5vw; //button mobile
$fzbuttond:2.1vh; //button desktop

//h2
$fzh2m:2.8rem;
$fzh2t:5.2rem;
$fzh2:4rem;
$fzh2card:1.8vw;
$fzh2cardt:4rem;
$fzh2cardm:2.5rem;
$fzmct:3.2rem;



$fzNavbard:2.8rem;
$fzNavbard-labtop:2.3rem;
$fztriangle:1.5rem;
$fzh1: 6rem;
$fzh3:3rem;
$fzh4:2rem;
$fzmc:2.5rem;
$fzmcc:1.8rem;

$fzbuttont:1.5rem; //button tablet
$dropdown:2.2rem;
$fzdropdown--menu:1.6rem;
$fzdropdown--menu--tablet:2.4rem;
$fzdropdown--menu--desktop:2rem;
$fzcontentCardm:1.5rem;