.prestations{
  position: absolute;
  top:15vh;
  left:50%;
  transform: translatex(-50%);
  width:100%;
  background-color: #f1f1f1;
  overflow: hidden;
  z-index: 2;
}
.container--prestations{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin:1rem auto;
  width:95%;
}

.form-title--prestations > .underline{
  font-size: 2.2rem;
  color:#474544;
}
.descriptif-prestations{
  font-size:2rem;
  margin:0rem 0.5rem 1rem;
  color:#474544;
  text-align:center;
  line-height: 1.4;
}
.container--card-prestations{
  display: flex;
  flex-direction: column;
}
.card-prestations{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  margin: 1rem auto;
  padding: 0 1rem ;
  flex-shrink: 0;
}
.img-prestations{
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width:90vw;
  padding:0 0.5vw;
  margin:2rem auto 1rem;
  overflow: hidden;
}
.content-prestation{
  width: 95vw;
  margin:0 auto 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.underline, .contact--prestations{
  font-size: 2.2rem ;
}
.underline, .contact--prestations{
  font-size: 2.8rem ;
}
p, h3, .underline{
  margin-top:1rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  line-height: 1.3;
}
.underline, .title--prestation{
  color:#474544;
  font-size: 2.4rem;
}
.slug{
  font-size: 1.6rem;
  text-align: center;
}
.espace{
  position: relative;
  height: 75vh;
  width: 99vw;
}
.p-descriptif{
  text-align: center;
  line-height: 1.6;
  font-size: 1.6rem;
  padding: 0 rem;
}
.separateur{
  margin: 5rem auto 3rem;
  border:1px solid $red;
  width: 60vw;
}
.sommaire{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 1rem 0;
 
  &--item{
    font-size: 1.7rem;
    padding:0.5rem;
  }
}
.fa-arrow-circle-right{
   margin-left: 0.5rem;
   color:$red;
   cursor: pointer;
}
.logo--prestations{
  margin:9vh;
  width:8rem;
  height:auto;
}
.tv-enchanté{
  display: inline-block;
  width:40%;
  height:auto;
}
@media screen and(min-width:768px){
  .prestations{
    top:15vh;
    width:100vw;
  }
  .card-prestations{
    margin: 2rem 0;
    width: 100vw;
  }

  .form-title--prestations > .underline{
    font-size: 4rem;
  }
  .descriptif-prestations{
    font-size: 2.4rem;
  }
  p , .underline{
    font-size:3.2rem;
  }
  .p-descriptif{
    font-size: 2.5rem;
    padding: 0 2rem;
  }
  .sommaire{
  
    &--item{
      font-size: 1.8rem;
      padding-right: 2rem;
    }
   }
}

@media screen and(min-width:1200px){
  .prestations{
    top:15vh;
    width:100vw;
  }
  .container--card-prestations{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .content-prestation{
    width: 35vw;
    margin:0vw;
  }
  .card-prestations{
    width:40%;
    margin:1vw 1vw;
    flex-shrink: 0;
    border-radius: 0.5rem;
  }
 h3{
   margin:1vw auto 1vw;
  }
  .form-title--prestations > .underline{
    font-size: 4rem;
  }
  .img-prestations{
    height: auto;
    width:35vw;
  }
  .contact--prestations{
    font-size: 3rem !important;
    font-family: $ffdropdown--menu;
  }
  .sommaire{
    flex-direction: row;
    margin:4rem 6rem 0 ; 
    &--item{
      font-size: 2rem;
      padding-right: 2.5rem;
    }
   }
  .logo--prestations{
    display: none;
  }
  .tv-enchanté{
    width:20%;
    height: auto;
  }
  .separateur{
    margin: 5rem auto 3rem;
    border:1px solid $red;
    width: 30vw;
  }
  .slug{
    font-size: 3rem;
  }
}

