* {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  list-style: none;
 }
  html, body{
    margin:0;
    padding:0;
    box-sizing: border-box;
  }

 html{
   font-size: 62.5%;
 }
 
 body {
   font-family: 'dancing-script', sans-serif;
 }
 a{
   text-decoration: none;
   color:inherit;
   cursor: pointer;
 }
 .body{
   display:flex;
   flex-direction:column;
   justify-content:flex-start;
   min-height:100vh;
   width:100%;
   margin: 0 auto;
   overflow-x: hidden;
   background-color: #f1f1f1;
 }
 .weight--p{
  font-weight: 620;
 }
 .underline{
  border-bottom:1px solid $red;
  font-weight: 600;
  font-family: $ffp;
  text-transform: capitalize;
  &--title{
    border-bottom:2px solid $red;
    font-size: 2.2rem;
  }
  &--link{
    font-weight: normal;
    text-transform: unset;
    cursor: pointer;
  }
  p, h3, .underline{
    font-size: 2.2rem;
  }
}


