.aboutus{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  z-index: 1;
}
.aboutus--title{
  margin: 0 auto -5vh;
  color:#474544;
  font-family: $ffp;
  text-align: center;
}
.aboutus--img{
  margin-top: 10%;
}
.aboutus--marg{
  margin-top: 40% !important;
}
.form-title--aboutus > .underline{
  font-size: 2rem;
}
.aboutus--container, .aboutus--portrait__card{ 
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color:$white;
  width:95vw;
  margin: 2rem auto 0;
  border-radius: 0.5rem;
}
.card--main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:95vw;
}

.main__about--image{
  width:100%;
  height:auto;
  
}
.card__description{
  display: block;
  margin: 0rem;
  padding:0.5vw;
  text-align: center;
  font-size: $fzmcm;
  border-radius: 0 0 1.7rem 1.7rem;
  line-height: 1.5;
  overflow: hidden;
  &_:hover{
    display:block;
    transition: all 5s ease;
  }
}
.card--content{
  color:#474544;
  font-size: 1.4rem;
  line-height: 1.5;
  font-family: helvetica,sans-serif;
  padding: 0.5vw;
}
.titletwo{
  margin: 0.5rem auto 0rem;
  font-family: $ffp;
  font-size: $fzh2cardm;
  text-align: center;
  font-weight: bold;
  color:#474544;
  text-transform: capitalize;
}

  //card
 
  .card__container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:90vw;
    height:62vw;
    margin:2rem auto;
    overflow: hidden;
    & figure{
      display: flex;
      justify-content: center;
    }
    & img{
    width:90vw;
    height: auto;
    max-height: 55vw;
    }
  }
  .card-color{
    position: absolute;
    background-color: $red;
    width:110vw;
    height: 30vw;
    transform: rotate(148deg);
  }
  .card{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .presentation{
    margin: 0rem auto ;
    width:100%;
    text-align: center;
  }
  
  .musicien{
    margin: 6rem auto ;
    width:90%;
  }
  .card-content{
    position: relative;
    top:20vw;
    line-height: 2.8rem;
    text-align: center;
    color:$red;
    font-family: $ffp;
    font-size: 4.5vw;
    font-weight: bold;
    z-index: 1;
  }
  .content-p{
    position: absolute;
    top:6rem;
    font-weight: normal;
  }
  .content-pf{
    top:0vh;
    margin:0 1rem 4vh;
    line-height: 1.4;
    color:transparent !important;
    -webkit-background-clip:text;
    background-image: linear-gradient(to right,#3b3a3a 35%, #f8008c);
  }
 
  @media screen and (orientation: landscape)and(min-height:300px) and(max-height:700px){
    .aboutus{
      margin-top: 9rem;
    }
  }
  //tablet
  @media screen and (min-width:768px){
    .aboutus--title{
      margin: 5rem auto;
      color:#474544;
      font-family: $ffp;
      text-align: center;
    }
    .form-title--aboutus > .underline{
      font-size: 4rem;
    }
    .titletwo{
      font-size: $fzh2cardt;
    }
    .card-content{
      position: relative;
      top:15rem;
      font-size: 4.5rem;
    }
    .card--content{
      color:#474544;
    }
    .card--content{
      font-family: helvetica, sans-serif;
      font-size: 2.5rem;
    }

   //cards 

   .aboutus--img{
    margin-top: 0rem;
  }
  .card--presentation{
    width: 100%;
    text-align: center;
  }
  .presentation{
    width:90%;
    border-radius: 1rem;
    margin: 0rem auto 4rem;
  } 
  .card-content{
    top:10vh;
    font-size: 5vw;
  }
  .card-artist{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
  }
.card__container{
    width:55vw;
    height:35vw;
    margin:2rem 2rem;
    & img{
    width: 55vw;
    height: auto;
    max-height:31vw;
    border-radius: 2rem 0 2rem;
    }
  }
  .card-color{
    width:110vw;
    height: 12vw;
  
  }
  .content-p{
    position: absolute;
    top:6rem;
    font-size: 2.8rem;
  }
  .content-pf{
    top:0vh;
    font-size: 3rem;
    margin:2rem auto 4rem;
    line-height: 1.4;
  }
}
  
  //desktop
  @media screen and (min-width:1200px){
   
    //  .aboutus-container{
    //   margin:6rem auto 8rem;
    // }
    .aboutus--title{
      background-color:transparent;
      font-size: $fzh2;
    }
    .form-title--aboutus > .underline{
      font-size: 4rem;
    }
    .aboutus--marg{
      margin-top: 10% !important;
    }
    .card__description{
      display: flex;
      flex-wrap: wrap;
      font-size: $fzmct;
      padding: 2rem;
    }
    .card--main{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: justify;
      overflow: hidden;
      //max-height: 73vh;
      flex-shrink: 0;
    }
    .card__description {
      width:100%;
      display: flex;
      flex-wrap: wrap;
      margin: 1rem;
    }
    .card__description p{
      display: flex;
      flex-wrap: wrap;
      margin: 1rem;
    }
    .main__about--image{
      display: block;
      border-radius: 0.5rem  0 0 0.5rem;
      width:auto;
      min-width: 50%;
      height:100%;
      max-height: 100vh;
    }
    .titletwo{
      font-size: $fzh2card;
    }
    .card--content{
      font-size: 1vw;
      line-height: 1.7;
      text-align: center;
    }
    //cards 

    .aboutus--img{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 4rem;
    }
    .card--presentation{
      width: 80%;
      text-align: center;
    }
    .presentation{
      width:70%;
      //border-radius: 2rem;
    } 
    .card-content{
      top:12vh;
      font-size: 2.5vw;
    }
    .card-artist{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .card__container{
      width:28vw;
      height:21vw;
      margin:2rem 2rem;
      padding:0 6rem;
     
      & img{
      width: 28vw;
      height: auto;
      max-height:20vw;
      border-radius: 3rem 0 3rem 0;
      }
    }
    .card-color{
      width:110vw;
      height: 10vw;
    }
    .content-p{
      position: absolute;
      top:6rem;
      font-size: 3.5rem;
    }
    .content-pf{
      top:0vh;
      font-size: 3.5rem;
      margin:2vh 0 8vh;
      line-height: 1.4;
    }
  }