.section--404{
  position: absolute;
  top: 45%;
  left:50%;
  transform: translate(-50% , -50%);
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:1rem;
  background-color: transparent;
  opacity:1;
  border-radius:1rem;
  overflow: hidden;
  z-index: 2;
}
.container--404{
  position: relative;
  background-color: $white;
  opacity: 1;
  width: 100vw;
  padding-bottom:2rem;
  margin: 0 auto;
}
.form-title--404{
  font-size: 2rem;
  margin: 2rem 1vw;
  text-align: center;
}
.return{
  width:80vw;
  margin: 2rem auto;
  height: 8.5vw;
  line-height: 8.5vw;
  cursor: pointer;
  font-size: 1.7rem;
  background-color: #fff;
  border: 2px solid #474544;
  &:hover{
    background-color: #474544;
    color:#fff;
  }
}
@media screen and (min-width:768px){
  .return{
    width:50vw;
  }
}
@media screen and (min-width:1200px){
  .section--404{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50% , -50%);
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:1rem;
    background-color: transparent;
    opacity:0.98;
    border-radius:1rem;
    overflow: hidden;
    z-index: 2;
  }
  .container--404{
    position: relative;
    background-color: $white;
    opacity: 1;
    width: 100vw;
    padding-bottom:2rem;
    margin: 0 auto;
  }
  .return{
    width:20vw;
    margin: 2rem auto;
    height: 2.5vw;
    line-height: 2.5vw;
    cursor: pointer;
    font-size: 2.4rem;
    background-color: #fff;
    border: 2px solid #474544;
    &:hover{
      background-color: #474544;
      color:#fff;
    }
  }
}