.main{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow:hidden;
  height: 15vh;
}
.main__container{
  position: absolute;
  top: 14vh;
  left:50%;
  right:50%;
  display: inline-block;
  transform: translate(-50%, 0%);
  display: inline-block;
  color: $white;
  text-align: center;
  width: 85%;
}

.main__title{
  margin-bottom:3rem;
  font-size: $fzh1m;
  font-family: $ffh1;
  text-transform: capitalize;
  text-align: center;
  animation: apparition1 2s ease;
}

.play, .main__content > span{
  color:$red;
}
@keyframes apparition1{
  from{
      opacity: 0;
      transform: translateY(-10vw);
  }
  to{
      opacity:1;
      transform:translateY(0vw);
  }
}
.main__content{
  margin: 1.5rem 0;
  color:transparent !important;
  -webkit-background-clip:text;
  background-image: linear-gradient(to right, #fff 50%, #f8008c 74%);
  font-size: $fzmcm;
  font-family: $ffmc;
  animation: apparition2 2.2s ease-out;
}
@keyframes apparition2{
  from{
    opacity: 0;
    transform: translateX(25vw);
  }
  to{
    opacity:1;
    transform:translateX(0vw);
  }
}

//tablet
@media screen and (min-width:768px){
  .main__title{
    font-size: $fzh1t;
    //animation: apparition1 2s ease;
  }
  .main__content{
    font-size:3.3rem;
  }
}
//desktop
@media screen and (min-width:1200px){
  .main{
    height:20vh;
  }
  .main__container{
    top: 35rem;
    left:65%;
    width: 40vw;
    max-width: 45vw;
  }
  .main__title{
    font-size: 7rem;
    //animation: apparition1 2s ease;
  }
  .main__content{
    font-size:3rem;
  }
}