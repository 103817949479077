html {
  font-family: $ffmtt;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
button {
  overflow: visible;
}
button, select {
  text-transform: none;
}
button, input, select, textarea {
  color: #5A5A5A;
  font: inherit;
  margin: 0;
}
input {
  line-height: normal;
}
textarea {
  overflow: auto;
}
.dnone{
  display: none;
}
.form__content{
  position: absolute;
  top: 13%;
  left:50%;
  transform: translate(-50%);
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:1rem;
  background-color: transparent;
  opacity:0.98;
  border-radius:1rem;
  overflow: hidden;
  z-index: 2;
}
.container {
  position: relative;
  background-color: $white;
  opacity: 1;
  width: 100vw;
  padding-bottom:2rem;
  margin: 0 auto;
}
#contact-form{
  padding-top: 0;
}
.form-title{
  margin:2.5rem 0 4.5rem;
  color: #474544;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 7px;
  line-height: 3rem;
  text-align: center;
  text-transform: capitalize;
  font-family: $ffp;
}
.telperso{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#474544; 
}
.telperso--title, .telperso--content{
  font-size: 1.8rem;
  font-weight: 500;
  margin:0.5rem 0;
}
.telperso--content:last-child{
  margin-top:2.5rem;
}
form {
  padding: 1.5rem;
  margin: 0rem 0;
}
.email {
	float: right;
	width: 100%;
}
input[type='text'], [type='tel'],[type='email'], select, textarea {
	background: none;
  border: none;
	border-bottom: solid 2px #474544;
	color: #474544;
  font-size: 2.000rem;
  font-weight: 400;
  letter-spacing: 1px;
	margin: 0rem 0 0.875rem 0;
	padding: 2rem 0 0.875rem 0;
  width: 100%;
  height: 6rem;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
  transition: all 0.3s;
}

input:focus, textarea:focus {
	outline: none;
  padding: 2rem 0 0.875em 0;
  border-bottom: solid 2px $red;
}
.telperso--content-number{
  height: 2rem;
  padding: 1rem 0 ;
}
.message {
  float:none;
}

.name {
  float:none;
}
.telephone {
    float: none;
}
  .object {
    width: 100%;
  }
  
textarea {
	line-height:150%;
	height: 15rem;
	resize: none;
  width: 100%;
  font-size: 2rem;
}

::-webkit-input-placeholder {
  color: #474544;
  font-size: 1.2rem;

}

:-moz-placeholder { 
  color: #474544;
  font-size: 1.2rem;
	opacity: 1;
}

::-moz-placeholder {
  color: #474544;
  font-size: 1.2rem;
	opacity: 1;
}

:-ms-input-placeholder {
  color: #474544;
  font-size: 1.2rem;
}

#form_button {
  background: none;
  border: solid 2px #474544;
  color: #474544;
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  outline: none;
  margin:2rem 0; 
  padding: 1.5rem 3rem;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
}

#form_button:hover {
  background: #474544;
  color: #F2F3EB;
}
.error{
  color:red;
  margin:0.2rem;
  padding:0;
  line-height: 0;
  font-size: 1.2rem;
}
.thanks-h, .thanks-v{
  margin: 0;
  padding: 1vw;
  font-size: 1.6rem;
}
.thanks-h{
  visibility: hidden;
}
.thanks-v{
  visibility: visible;
  font-size: 1.6rem;
  color: $red;
  text-align: center;
  margin:1rem 0 0;
}
.mailto{
  display:flex;
  flex-direction: column;
  align-items: center;
}
i.fas.fa-envelope.enveloppemail{
 font-size: 15rem;
}
.container-2{
  margin:3rem auto;
  padding:5rem 0 15rem;
}
//tablet
@media screen and (min-width: 768px) {
  #container {
    margin: 2rem auto;
    padding:3rem 0;
    width: 95%;
  }
  .name {
    float: left;
    width: 45%;
  }
  .telephone {
    float: right;
    width: 45%;
  }
}

//desktop
@media screen and (min-width: 1200px) {
  .form__content{
    position: absolute;
    top: 10vh;
  }
  #container {
    margin: 3rem auto;
    padding:3rem 0;
    width: 50%;
  }
  .form-title{
    color: $black;
    font-size: 3.2rem;
    font-weight: 600;
    letter-spacing: 7px;
    line-height: 3rem;
    text-align: center;
    text-transform: capitalize;
    font-family: $ffp;
    margin:1rem 0 4rem;
  }
  form{
    padding: 4rem;
  }
  textarea{
    height: 25rem;
  }
  .mailto{
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  i.fas.fa-envelope.enveloppemail{
   font-size: 15rem;
  }
  .container-2 {
    margin: 10rem auto;
    padding: 5rem 0 15rem;
    width: 50%;
}
}
